// 拼团专区
import * as API from '@/api/index'
export default {
    /**  拼团列表*/
    GetBookingList: params => {
        return API.POST('api/GroupBooking/getBookingList', params)
    },
    /**  拼团新增*/
    AddGroupBooking: params => {
        return API.POST('api/GroupBooking/addGroupBooking', params)
    },
    /**  拼团编辑*/
    UpdateGroupBooking: params => {
        return API.POST('api/GroupBooking/updateGroupBooking', params)
    },
    /**  商品列表*/
    GoodAll: params => {
        return API.POST('api/Commodity/all', params)
    },
    /**  商品分类*/
    GoodType: () => {
        return API.POST('api/Commodity/GoodType')
    },
    /**  线上分类 - 排除没有子类的分类*/
    AppletGoodsCategoryValid: params => {
        return API.POST('api/appletGoodsCategory/valid', params)
    },
    /**  会员等级*/
    CustomerLevel: params => {
        return API.POST('api/customerLevel/all', params)
    },
    /**  获取商品信息*/
    GoodInfo: params => {
        return API.POST('api/Goods/allGoods', params)
    },
}